import * as React from "react";
import styled from "@emotion/styled";
import {useMemo, useState} from "react";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import {SearchOutlined} from '@ant-design/icons'
import SearchToolSelector from "./SearchToolSelector";
import { Form } from "antd";
import jsonRequest from "../../../utils/request/jsonRequest";
import workTypes from "../../../utils/options/workTypes";
import workStatus from "../../../utils/options/workStatus";
import {useRequest} from "ahooks";

const Container = styled.div`
  padding: 22px 32px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  min-height: 30px;
  display: flex;
  gap: 16px;
  align-items: start;
`

const RowName = styled.div`
  font-size: 14px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
`

const RowItems = styled.div`
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
`

const RowItem = styled.div`
  padding: 0 12px;
  height: 30px;
  line-height: 30px;
  &:hover {
    cursor: pointer;
  }
  &.selected {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 20px;
  }
`

const SearchInput = styled.div`
  height: 40px;
  width: 346px;
  border: 1px solid #999;
`

const options = [
  ...(['remote', 'site',].map(key => ({
    id: key,
    text: workTypes[key],
  }))),
  ...(['fullTime', 'partTime', 'unemployed', ].map(key => ({
    id: key,
    text: workStatus[key],
  })))
]

const SearchTools = ({onSearch = values => Promise.resolve()}) => {

  const [form] = Form.useForm()

  // const [cities, setCities] = useState([])
  const [searching, setSearching] = useState(false)

  const {data} = useRequest(() => {
    return jsonRequest("/resume/options").then(rsp => rsp.data?.data)
  })

  const jobOptions = useMemo<any[]>(() => {
    return data?.jobs?.map(job => {
      return {
        id: job.id,
        text: job.name,
      }
    }) ?? []
  }, [data])

  const cityOptions = useMemo(() => {
    return [
      ...(data?.regions?.filter(region => {
        return region.acronym !== 'CN'
      }).map(region => ({
        id: `region:${region.id}`,
        text: region.name,
      })) ?? []),
      ...(data?.cities?.map(city => ({
        id: `city:${city.code}`,
        text: city.name,
      })) ?? [])
    ]
  }, [data])

  return (<Form
    onValuesChange={(changeValue, values) => {
      const keys = Object.keys(changeValue)
      for (let i = keys.length - 1; i >= 0; i--) {
        if (keys[i] != "search") {
          form.submit()
          return;
        }
      }
    }}
    onFinish={values => {
      if (searching) {
        return;
      }
      const data = {...values}
      if (data.region) {
        if (data.region.match(/^city:.*$/)) {
          data.cityCode = data.region.replace(/^city:(.*)$/, "$1");
        }
        if (data.region.match(/^region:.*$/)) {
          data.regionId = data.region.replace(/^region:(.*)$/, "$1")
        }
      }
      setSearching(true);
      onSearch(data).catch(console.error).finally(() => {
        setSearching(false)
      })
    }}
    {...{form}}
  >
    <Container>
      <Row>
        <RowName>查找</RowName>
        <Form.Item name='option' noStyle>
          <SearchToolSelector data={options} hideShowMore/>
        </Form.Item>
        <div>
          <Input.Group compact size='large' style={{display: 'flex', width: 346, borderRadius: 4, float: 'right', margin: '-5px 0'}}>
            <Form.Item name='search' noStyle>
              <Input style={{ flex: 1, marginRight: -3 }} placeholder='输入关键词搜索内容' />
            </Form.Item>
            <Button
              type="primary"
              size='large'
              onClick={form.submit}
              loading={searching}
              style={{
                backgroundColor: '#000',
                borderColor: '#000',
                fontSize: 14,
                borderRadius: 4,
                width: 80
              }}
            >
              {!searching && (<><SearchOutlined size={16}/>搜索</>)}
            </Button>
          </Input.Group>
        </div>
      </Row>
      <Row style={{marginTop: 24}}>
        <RowName>地区</RowName>
        <Form.Item name='region' noStyle>
          <SearchToolSelector data={cityOptions}/>
        </Form.Item>
      </Row>
      <Row style={{marginTop: 24}}>
        <RowName>职业</RowName>
        <Form.Item name='job' noStyle>
          <SearchToolSelector data={jobOptions}/>
        </Form.Item>
      </Row>
    </Container>
  </Form>)
}

export default SearchTools;
