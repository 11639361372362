import * as React from "react";
import styled from "@emotion/styled";
import {useState} from "react";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

const lineHeight = 30

const RowItems = styled.div`
  flex: 1;
  display: flex;
  min-height: ${lineHeight}px;
  gap: 10px;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
`

const RowItem = styled.span`
  padding: 0 12px;
  height: ${lineHeight}px;
  line-height: ${lineHeight}px;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
  &.selected {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 20px;
  }
`

const HideButton = styled.span`
  line-height: ${lineHeight}px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
  margin-left: auto;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  & > .anticon {
    margin-right: 6px;
    font-size: 12px;
  }
`

const ShowButton = styled.div`
  line-height: ${lineHeight}px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
  
  &:hover {
    cursor: pointer;
  }
  
  & > .anticon {
    margin-left: 6px;
    font-size: 12px;
  }
`

const SearchToolSelector = ({
  onChange = (v) => {},
  value = null,
  hideShowMore = false,
  data = [],
                            }) => {
  const [showAll, setShowAll] = useState(false)
  return (<>
    <RowItems style={{height: showAll ? "auto" : lineHeight}}>
      <RowItem
        className={value == null ? "selected" : ""}
        onClick={() => onChange(null)}
      >全部</RowItem>
      {data.map(item => (
        <RowItem
          key={item.id}
          className={value == item.id ? "selected" : ""}
          onClick={() => onChange(item.id)}
        >{item.text}</RowItem>
      ))}
      {/*{showAll && (*/}
      {/*  <HideButton style={{marginLeft: 'auto'}} onClick={() => setShowAll(false)}><LeftOutlined /> 收起</HideButton>*/}
      {/*)}*/}
    </RowItems>
    {!hideShowMore && !showAll && (
      <ShowButton onClick={() => setShowAll(true)}>全部<RightOutlined/></ShowButton>
    )}
    {showAll && (
      <HideButton style={{marginLeft: 'auto'}} onClick={() => setShowAll(false)}><LeftOutlined /> 收起</HideButton>
    )}
  </>)
}

export default SearchToolSelector
