import * as React from "react";
import Header, {HeaderNav} from "../../components/header";
import Seo from "../../components/seo"
import styled from "@emotion/styled";
import SearchTools from "../../components/pages/organize/SearchTools";
import ResumeCard from "../../components/pages/organize/ResumeCard";
import {useEffect, useState} from "react";
import jsonRequest from "../../utils/request/jsonRequest";
import workTypes from "../../utils/options/workTypes";
import * as queryString from 'query-string';
import workStatus from "../../utils/options/workStatus";
import InfiniteScroll from "react-infinite-scroll-component";
import {useRequest} from "ahooks";
import {useSelector} from "react-redux";
import {Link} from "gatsby";
import Footer from "../../components/footer";

const bodyWidth = 1000;

const OrganizePageBody = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f9f9f9;
`

const Container = styled.div`
  padding-top: 40px;
  margin: 0 auto;
  width: ${bodyWidth}px;
`

const List = styled.div`
  padding-top: 16px;
  width: ${bodyWidth}px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const AdvLink = styled.a`
  
`
const Adv = styled.img`
  margin: 0;
  width: ${bodyWidth/2-8}px;
  //width: 356px;
  height: ${(bodyWidth/2-8)/356*154}px;
  border-radius: 8px;
  object-fit: cover;
`

const LoginLink = styled.div`
  padding: 30px 0 30px;
  text-align: center;
  a {
    color: #1890ff;
  }
`

let page = 1
const pageSize = 10

const OrganizePage = () => {
  const {account} = useSelector((selector: any) => selector)
  const [searchValues, setSearchValues] = useState()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [showLoginLink, setShowLoginLink] = useState(false)

  const {data: adverts} = useRequest(() => {
    return jsonRequest(`/advert?position=organize`).then(rsp => {
      return rsp.data?.data ?? []
    })
  })

  const load = (p = 1, params = {}) => {
    page = p
    if (loading) {
      return Promise.resolve();
    }
    if (!account && page > 2) {
      setShowLoginLink(true)
      return Promise.resolve();
    }
    params['current'] = page;
    params['pageSize'] = pageSize
    setLoading(true)
    const query = {}
    Object.keys(params).map(key => {
      if (params[key] !== null && params[key] !== undefined) {
        query[key] = params[key]
      }
    })

    return jsonRequest(`/resume?${queryString.stringify(query)}`).then(rsp => {
      setData([...(page <= 1 ? [] : data), ...(rsp.data.data?.map(item => ({
        id: item.id,
        name: item.nickname,
        avatar: item.avatar,
        title: item.resume.title,
        certificated: item?.resumeSetting?.auditStatus == 'auditSuccess' ?? false,
        skills: item.skills?.map(skill => skill.name) ?? [] ,
        // projects: ['程栈网', '腾讯文档', '蓝湖', '微信公众号', '支付宝', '百度'],
        projects: item.cases,
        isShowResume: item.isShowResume,
        tags: [...(item.resume.region.acronym === 'CN' ? (item.city ? [item.city] : []) : [item.resume.region?.name]), `${item.resume.workYear}年经验`, workStatus[item.resume.workStatus] ?? item.resume.workStatus, workTypes[item.resume.workType], ...(item.resume?.job ? [item.resume.job?.name] : [])],
      })) ?? [])])
    }).catch(console.error).finally(() => {
      setLoading(false)
    })
  }

  const handleSearch = (values) => {
    setSearchValues(values)
    return load(1, values)
  }
  useEffect(() => {
    load()
  }, [])
  return (
    <OrganizePageBody>
      <Header selected={HeaderNav.Organize} authorized/>
      <Container>
        <SearchTools onSearch={handleSearch}/>
        <InfiniteScroll
          dataLength={data.length}
          next={() => {
            // run(++page)
            load(++page, searchValues)
          }}
          hasMore={true}
          loader={
            // data.length > 0 && <div style={{padding: 40, textAlign: "center"}}><Spin/></div>
            data.length > 0 && <div></div> // todo has more
          }
          // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List>
            {data?.map((item, i) => (
              <>
                {i % pageSize == 2 && adverts?.slice(Math.floor(i / pageSize)*2, Math.floor(i / pageSize)*2+2).map(advert => advert.isRedirect && (
                  <AdvLink key={`adv-${advert.id}`} href={advert.url} target='_blank'>
                    <Adv src={advert.image}/>
                  </AdvLink>
                ) || (
                  <Adv key={advert.id} src={advert.image}/>
                ))}
                <ResumeCard key={`rs-${item.id}`} {...item}/>
              </>
            ))}
          </List>
        </InfiniteScroll>

        {/*{*/}
        {/*  loading && (*/}
        {/*    <div style={{padding: 40, textAlign: "center"}}><Spin/></div>*/}
        {/*  )*/}
        {/*}*/}
      </Container>
      {showLoginLink && (
        <>
          <LoginLink><Link to={`/sign/in?redirect=/organize/`}>登陆</Link>后查看更多</LoginLink>
          <Footer/>
        </>
      )}
    </OrganizePageBody>
  )
}

export default OrganizePage

// @ts-ignore
export const Head = () => <Seo title='GitWork-1小时组建IT团队' keywords={["web3","web3.0","web3人才招聘","web3人才猎头","web3远程办公","web3工作","劳务派遣","人力外包","人才猎头公司","招聘求职"]} />
