import * as React from "react";
import {navigate} from "gatsby";
import styled from "@emotion/styled";
import config from "../../../config";
import {message, Popover} from "antd";

const Container = styled.div`
  padding: 24px;
  width: calc(50% - 8px);
  //width: 300px;
  background: #ffffff;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    box-shadow: ${config.styles.hoverShadow};
  }
`

const Header = styled.div`
  display: flex;
  //align-items: center;
`

const Avatar = styled.img`
  margin: 0;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`

const HeaderContent = styled.div`
  margin-left: 16px;
  max-width: calc(100% - 70px);
`

const Name = styled.div`
  margin: 0;
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang PingFang SC-Bold, PingFang SC, sans-serif;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: start;
  .nameText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const Certificate = styled.img`
  margin: 0 0 0 6px;
  height: 20px;
  width: 58px;
  vertical-align: top;
`

const Title = styled.div`
  margin-top: 4px;
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
`

const Body = styled.div`
  margin-top: 24px;
`

const Labels = styled.div`
  margin-top: 6px;
  height: 22px;
  line-height: 22px;
  display: flex;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  flex-wrap: wrap;
`

const Label = styled.div`
  white-space: nowrap;
  &:not(:first-of-type):before {
    content: "、";
  }
`

const Tags = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 6px;
`

const Tag = styled.div`
  padding: 0 12px;
  line-height: 28px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 7em;
`

const ResumeCard = (props) => {

  const {id} = props

  const {avatar, name, title, certificated = false, skills, projects, tags, isShowResume, ...containerProps} = props;

  return (
    <Container
      {...containerProps}
      onClick={() => {
        if (isShowResume) {
          window.open(`/team?id=${id}`);
        } else {
          message.warning('该用户履历已设置隐藏')
        }
      }}
    >
      <Header>
        {
          <Avatar src={avatar}/>
        }
        <HeaderContent>
          <Name><div className='nameText'>{name}</div>{certificated && (<Certificate src={"/images/icons/cert.png"}/>)}</Name>
          <Title>{title}</Title>
        </HeaderContent>
      </Header>
      <Body>
        <Labels>
          {skills?.map((skill, i) => (
            <Label key={i}>{skill}</Label>
          ))}
        </Labels>
        <Labels>
          {projects?.map((project, i) => (
            <Label key={i}>{project}</Label>
          ))}
        </Labels>
        <Tags>
          {tags?.map((tag, i) => (
            <Popover key={i} content={tag}>
              <Tag>{tag}</Tag>
            </Popover>
          ))}
        </Tags>
      </Body>
    </Container>
  )
}

export default ResumeCard;
